(function ($) {
    'use strict';

    var root = this;


    /**
     *
     * @param name
     * @param value
     * @param days
     */
    root.zgCreateCookie = function (name, value, days) {
        var expires = '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

            expires = '; expires=' + date.toGMTString();
        }

        document.cookie = name + '=' + value + expires + '; path=/';
    };


    /**
     *
     * @param name
     * @returns {*}
     */
    root.zgReadCookie = function (name) {
        var nameEQ = name + '=';
        var ca     = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    };


    /**
     *
     * @param name
     */
    root.zgEraseCookie = function (name) {
        root.zgCreateCookie(name, '', -1);
    };


    // cookie policy alert and cookies
    $(function () {
        var cookieName       = 'ZG-cookiepolicy-' + window.SGL_JS_THEME,
            containerContent = 'content_cookie_policy',
            cookieFileName = 'cookie_policy.html',
            typeContainer    = $( '#content_cookie_policy' ).data( 'typeContainer' );

        // CHECK IF THERE IS COOKIE
        if (!root.zgReadCookie(cookieName)) {

            root.loadAjaxFile( cookieFileName, containerContent);

            $(document).on('zg.loadAjaxFile.success', function (e, fileName) {
                if (cookieFileName === fileName) {

                    if( typeContainer == "modal" ) {
                        $( '#cookieAlert' ).hide();
                        var $cookiepolicy = $(document).find( '[data-zg-role="cookie-modal"]' );
                        $cookiepolicy.on('hide.bs.modal', function () {
                            root.zgCreateCookie(cookieName, 'ZG Cookies Policy Accepted', 30);
                        });
                        $cookiepolicy.modal('show');
                    }
                    else{
                        var $cookiepolicy = $(document).find( '[data-zg-role="cookie-alert"]' );
                        $cookiepolicy.on('close.bs.alert', function () {
                            root.zgCreateCookie(cookieName, 'ZG Cookies Policy Accepted', 30);
                        });
                        $cookiepolicy.removeClass('hidden');
                    }
                }
            });

        }
    });

    /*
    // cookie newsletter modal
    $(function () {
        var newsletterContainer     = 'content_newsletter_modal',
            newsletterCookieName    = 'ZG-showNewsletter-' + window.SGL_JS_THEME,
            url                     = window.makeUrl( {
                    module: 'eshop',
                    manager: 'cms',
                    action: 'list',
                    type: 'block_newsletterModal',
                    master: 'master_blank',
                    skip_redir: '1'
                } );

        // CHECK IF THERE IS COOKIE
        if (!root.zgReadCookie( newsletterCookieName )) {

            root.loadAjaxFile(null, newsletterContainer, url);

            $(document).on('zg.loadAjaxFile.success', function (e, fileName, containerSelector) {
               
                    var $newsletterContainer    = $(document).find( '#' + newsletterContainer ),
                        $newsletterModal        = $newsletterContainer.closest('.modal'),
                        $newsletterForm         = $newsletterContainer.find('#form_newsletter_modal');

                    //Init any form to validate
                    $newsletterModal.find('[data-zg-validate="to-validate"]').zg_validate( window.ZG_CONFIG );

                    //Show modal
                    $newsletterModal.modal('show');

                    //Listen for modal close
                    $newsletterModal.on('hide.bs.modal', function () {
                        root.zgCreateCookie(newsletterCookieName, '1', 7); // 1 week
                    });

                    //When user submit the form
                    $newsletterForm.on('submit', function () {
                        root.zgCreateCookie(newsletterCookieName, '1', 7); // 1 week
                    });
                
            });

        }
    });
    */

    // cookie newsletter modal
    $(function () {
        var newsletterCookieName    = 'ZG-showNewsletter-' + window.SGL_JS_THEME;
           
        // CHECK IF THERE IS COOKIE
        if (!root.zgReadCookie( newsletterCookieName ) && $('#newsletterModal').length !=0 && window.NEWSLETTER_AUTOMATIC_MODAL == "1") {

            //Show modal
            setTimeout(function(){
                $('#newsletterModal').modal('show');
            },7000);
            

            //Listen for modal close
            $('#newsletterModal').on('hide.bs.modal', function () {
                root.zgCreateCookie(newsletterCookieName, '1', 6); // 1 week
            });

            //When user submit the form
            $('#newsletterModal form').on('submit', function () {
                root.zgCreateCookie(newsletterCookieName, '1', 7); // 1 week
            });

            $('[data-zg-role="link-to-newsletter-mobile"]').on('click', function () {
                root.zgCreateCookie(newsletterCookieName, '1', 7); // 1 week
            });

        
        }
    });

}).call(this, jQuery);
